//
// summernote.scss
//

@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/summernote.eot");
  src: url("../fonts/summernote.eot?#iefix") format("embedded-opentype"), url("../fonts/summernote.woff?") format("woff"), url("../fonts/summernote.ttf?") format("truetype")
}

.note-editor{
  &.note-frame {
    border: 1px solid $input-border-color;
    box-shadow: none;
    margin: 0;

    .note-statusbar {
      background-color: $gray-700;
      border-top: 1px solid lighten($gray-700,3%);
    }

    .note-editable {
        border: none;
        color: $gray-500 !important;
        background-color: lighten($gray-800,3%) !important;
    }
  }
}

.note-status-output {
  display: none;
}

.note-editable {
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  padding: $input-padding-y $input-padding-x;

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.note-popover .popover-content .note-color .dropdown-menu,
.card-header.note-toolbar .note-color .dropdown-menu {
    min-width: 344px;
}

.note-toolbar {
   z-index: 1;

   .btn-light {
     background-color: lighten($gray-700,4%);
     border-color: lighten($gray-700,4%);
     color: $gray-400;
   }
}